.button{
  &.button-with-small{
    position: relative;
  }
  .verysmall{
    font-size: 50%;
    line-height: 1;
    padding-top: 4px;
    opacity: 0.8;
    position: absolute;
    bottom: 0;
    right: 3px;
    z-index: 2;
  }
} 
.hasModal .split-pane-md{
  filter: blur(0.2rem);
}