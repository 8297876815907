.fabs{
  position: absolute;
  top: 0;   // surcharge en js
  left: 0;  // surcharge en js
  z-index: 11;

  .close{
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-30px, -30px);
  }
  .run{
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(20px, -30px);
  }
  .pin{
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(70px, -30px);
  }
  .share{
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(120px, -30px);
  }
  .fab-button-disabled{
    opacity: 1;
  }
}