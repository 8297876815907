.countdown-status{
  display: inline-block;
  vertical-align: middle;
  .step{
    display: block;
    font-weight: bold;
  }
  .status{
    display: block;
    font-style: italic;
    font-size: 0.8em;
    text-transform: uppercase;
  }
  .stopped-dots{
    display: block;
    line-height: 8px;
    margin-bottom: 2px;
    span{
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 3px;
      background: var(--ion-color-grey);
      border-radius: 50%;
      margin: 0 1px;
      opacity: 0.2;
    }
    &:before,
    &:after{
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 3px;
      background: var(--ion-color-grey);
      border-radius: 50%;
      margin: 0 1px;
      opacity: 0.2;
    }
  }
  .animated-dots{
    display: block;
    line-height: 8px;
    margin-bottom: 2px;
    span{
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 3px;
      background: var(--ion-color-grey);
      border-radius: 50%;
      margin: 0 1px;
      animation: blink-2 1s infinite;
    }
    &:before,
    &:after{
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 3px;
      background: var(--ion-color-grey);
      border-radius: 50%;
      margin: 0 1px;
    }
    &:before{
      animation: blink-1 1s infinite;
    }
    &:after{
      animation: blink-3 1s infinite;
    }
  }
  @media (prefers-color-scheme: dark) {
    .stopped-dots{
      span,
      &:before,
      &:after{
        background: var(--ion-color-dark);
      }
    }
    .animated-dots{
      span,
      &:before,
      &:after{
        background: var(--ion-color-dark);
      }
    }
  }
  body.dark &{
    .stopped-dots{
      span,
      &:before,
      &:after{
        background: var(--ion-color-dark);
      }
    }
    .animated-dots{
      span,
      &:before,
      &:after{
        background: var(--ion-color-dark);
      }
    }
  }

  @keyframes blink-1 {
    0% {
      opacity: 1;
    }
    33% {
      opacity: 0.2;
    }
    66% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.2;
    }
  }
  @keyframes blink-2 {
    0% {
      opacity: 0.2;
    }
    33% {
      opacity: 1;
    }
    66% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.2;
    }
  }
  @keyframes blink-3 {
    0% {
      opacity: .2;
    }
    33% {
      opacity: 0.2;
    }
    66% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}