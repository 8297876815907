.timeline{

  .ticks{
    width: calc( 100% - 24px );
    height: 1.3rem;
    position: relative;
    margin: 12px;
    margin-bottom: 0;
  }
  .tick{
    width: 2px;
    height: 1.3rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--ion-color-medium);
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    transform: translate(0px,0);
    transition: 0.3s;
    &.tick-end{
      transform: translateX(-2px);
      &.lowlight{
        background: var(--ion-color-primary);
        opacity: 0.3;
      }
      &.normal{
        background: var(--ion-color-primary);
      }
    }
  }
  .bullet{
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    position: absolute;
    top: -12px;
    left: 0;
    background: var(--ion-color-medium);
    color: var(--ion-color-white);
    transform: translateX(-50%);
    border-radius: 50%;
    // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    transition: 0.3s;
  }

  .lowlight{
    .tick{
      background: var(--ion-color-primary);
      opacity: 0.3;
    }
    .bullet{
      background: var(--ion-color-primary);
      opacity: 0.5;
    }
  }
  .highlight{
    .tick{
      background: var(--ion-color-primary);
    }
    .bullet{
      background: var(--ion-color-danger);
    }
  }
  .normal{
    .tick{
      background: var(--ion-color-primary);
    }
    .bullet{
      background: var(--ion-color-primary);
    }
  }

  .bar{
    width: calc( 100% - 24px );
    margin: 0 auto;
    .progress-bar-determinate{
      height: 10px;
      // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    }
  }
}