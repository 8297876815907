.menu-img{
  position: relative;
  &:before{
    content: '';
    display: block;
    margin: 0 auto;
    background: url(../medias/logo.png) no-repeat center center;
    background-size: 100%;
    width: 80px;
    height: 80px;
  }
}