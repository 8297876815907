.details-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.details-header{
  flex: 1;
}
.details-contents{
  height: 100%;
  overflow-y: auto;
}
#details{

  h1{
    margin-bottom: 0;
  }
  p{
    margin-top: 0;
  }
  
  .bookmark{
    position: absolute;
    top: -8px;
    left: 0;
    text-align: left;
  }

  .button{
    margin-right: 10px;
    margin-left: 10px;
  }
  .step-name{
    line-height: 1.8rem;
  }
  .bullet-basic{
    display: inline-block;
    margin-top: 5px;
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    background: var(--ion-color-medium);
    color: var(--ion-color-white);
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  }

  .cannot-run-message{
    font-style: italic;
    font-size: 0.8rem;
    margin-top: 0;
    color: var(--ion-color-primary);
  }
}