.time-table{
  .lowlight{
    opacity: 0.5;
    font-style: italic;
  }
  .highlight{
    color: var(--ion-color-danger);
  }

  .col-index{
    max-width: 2rem;
  }
}

.bullet-basic{
  display: inline-block;
  margin-top: 5px;
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
  background: var(--ion-color-medium);
  color: var(--ion-color-white);
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  transition: 0.3s;
  .highlight &{
    background: var(--ion-color-danger);
  }
}