.countdown-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.countdown-header{
  flex: 1;
}
.countdown-contents{
  height: 100%;
  overflow-y: auto;
}
#countdown{
  h1{
    margin-bottom: 0;
    margin-top: 10px;
    @media (max-width: 450px){
      font-size: 24px;
    }
  }
  p{
    margin-top: 0;
  }
  .button{
    margin-right: 10px;
    margin-left: 10px;
  }
  
}
.timeCircle{
  border-radius: 50%;
  background: var(--ion-color-grey);
  color: var(--ion-color-white);
  width: 100px;
  height: 100px;
  line-height: 40px;
  text-align: center;
  padding: 5px 0;
  display: inline-block;
}