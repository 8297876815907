.time-circle{
  border-radius: 50%;
  background: var(--ion-color-grey);
  color: var(--ion-color-white);
  text-align: center;
  display: inline-block;
  vertical-align: middle;

  &.started{
  }
  &.stopped,
  &.ended{
    opacity: 0.5;
    font-style: italic;
  }

  // dans le modal countdown
  &.normal{
    width: 70px;
    height: 70px;
    line-height: 60px;
    padding: 5px 0;
    @media (max-width: 450px){
      width: 47px;
      height: 47px;
      line-height: 37px;
    }
  }

  // dans le countdownBox : hauteur max 47px
  &.small{
    width: 47px;
    height: 47px;
    line-height: 47px;
  }
}