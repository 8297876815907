#search{
  
  ion-button.selected{
    --box-shadow: none;
  }
}
.dynamicHeader{
  transition: 0.3s;
  &.hidden{
    margin-top: -56px;
  }
  &.reduced{
    
  }
}
.no-more-card{
  &:after{
    content: '';
    display: block;
    width: 40%;
    margin: 0 auto;
    height: 1rem;
    border-bottom: 2px solid var(--ion-color-light);
  }
}