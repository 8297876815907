.time{
  white-space: nowrap;
  .hours{
    position: relative;
    &:after{
      content: 'h';
      position: absolute;
      top: 0px;
      vertical-align: super;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  // normal = dans le Countdown
  .hours{
    font-size: 2rem;
    &:after{
      font-size: .8rem;
      line-height: .8em;
    }
  }
  .minutes{
    font-size: 1.2rem;
  }
  .seconds{
    font-size: 0.7rem;
  }
  @media (max-width: 450px){
    .hours{
      font-size: 1.5rem;
      &:after{
        font-size: .7rem;
        line-height: .7m;
      }
    }
    .minutes{
      font-size: 0.8rem;
    }
    .seconds{
      font-size: 0.6rem;
    }
  }


  &.zero{
    .seconds{
      font-size: 2rem;
      @media (max-width: 450px){
        font-size: 1.5rem;
      }
    }
  }

  &.only-seconds{
    .seconds{
      font-size: 2rem;
      &:after{
        content: 's';
        vertical-align: baseline;
        text-decoration: none;
        text-transform: uppercase;
        font-size: .8rem;
        line-height: .8em;
      }
      @media (max-width: 450px){
        font-size: 1.5rem;
        &:after{
          font-size: .7rem;
          line-height: .7m;
        }
      }
    }
  }

  &.only-minutes{
    .minutes{
      font-size: 2rem;
      &:after{
        content: 'min';
        vertical-align: baseline;
        text-decoration: none;
        // text-transform: uppercase;
        font-size: .8rem;
        line-height: .8em;
      }
      @media (max-width: 450px){
        font-size: 1.5rem;
        &:after{
          font-size: .7rem;
          line-height: .7em;
        }
      }
    }
    .seconds{
      font-size: 1.2rem;
      @media (max-width: 450px){
        font-size: 0.8rem;
      }
    }
  }
  
  &.only-minutes-seconds{
    .minutes{
      font-size: 2rem;
      position: relative;
      &:after{
        content: 'm';
        position: absolute;
        top: 0px;
        vertical-align: super;
        text-decoration: none;
        // text-transform: uppercase;
        font-size: .8rem;
        line-height: .8em;
      }
      @media (max-width: 450px){
        font-size: 1.5rem;
        &:after{
          font-size: .7rem;
          line-height: .7em;
        }
      }
    }
    .seconds{
      font-size: 1.2rem;
      @media (max-width: 450px){
        font-size: 0.8rem;
      }
    }
  }


  // small = dans le countdownBox
  &.small{
    .hours{
      font-size: 1.1rem;
      &:after{
        font-size: .6rem;
      }
    }
    .minutes{
      font-size: 0.7rem;
    }
    .seconds{
      font-size: 0.5rem;
    }

    &.zero{
      .seconds{
        font-size: 1.1rem;
      }
    }
    &.only-seconds{
      .seconds{
        font-size: 1.1rem;
        &:after{
          font-size: .6rem;
        }
      }
    }
    &.only-minutes{
      .minutes{
        font-size: 1.1rem;
        &:after{
          font-size: .6rem;
        }
      }
    }
    &.only-minutes-seconds{
      .minutes{
        font-size: 1.1rem;
        &:after{
          font-size: .6rem;
        }
      }
      .seconds{
        font-size: 0.7rem;
      }
    }
  }
}