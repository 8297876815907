.searchbox{
  &.very-small{

  }
  &.small{

  }
  &.large{
    margin-top: 4rem;
    margin-bottom: 4rem;
    position: relative;

    .button{
      height: 6rem;
    }
  }
  &.disabled{
    opacity: 0.5;
  }


  .background-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 8rem;
    opacity: 0.2;
  }
}