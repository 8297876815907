/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */


.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}
.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #697680; /*#000000;*/
  --ion-color-light-contrast-rgb: 105,118,128; /*0, 0, 0;*/
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  /** grey **/
  --ion-color-grey: #697680;
  --ion-color-grey-rgb: 105,118,128;
  --ion-color-grey-contrast: #ffffff;
  --ion-color-grey-contrast-rgb: 255,255,255;
  --ion-color-grey-shade: #5c6871;
  --ion-color-grey-tint: #78848d;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #697680;
  --ion-color-white-contrast-rgb: 105,118,128;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --background: var(--ion-color-white);
}

@media (prefers-color-scheme: dark) {
  :root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #222428;
    --ion-color-primary-contrast-rgb: 34, 34, 34;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** white **/
    --ion-color-white: #222428;
    --ion-color-white-rgb: 34, 34, 34;
    --ion-color-white-contrast: #ffffff;
    --ion-color-white-contrast-rgb: 255, 255, 255;
    --ion-color-white-shade: #1e2023;
    --ion-color-white-tint: #383a3e;
    
    /** dark **/
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 244, 244;
    --ion-color-dark-contrast: #697680; /*#000000;*/
    --ion-color-dark-contrast-rgb: 105,118,128; /*0, 0, 0;*/
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;


    /** light **/
    --ion-color-light: #444444;
    --ion-color-light-rgb: 68,68,68;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #3c3c3c;
    --ion-color-light-tint: #575757;
  }
}
:root {
  body.dark{
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #222428;
    --ion-color-primary-contrast-rgb: 34, 34, 34;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** white -> dark **/
    --ion-color-white: #222428;
    --ion-color-white-rgb: 34, 34, 34;
    --ion-color-white-contrast: #ffffff;
    --ion-color-white-contrast-rgb: 255, 255, 255;
    --ion-color-white-shade: #1e2023;
    --ion-color-white-tint: #383a3e;
    
    /** dark -> light **/
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 244, 244;
    --ion-color-dark-contrast: #697680; /*#000000;*/
    --ion-color-dark-contrast-rgb: 105,118,128; /*0, 0, 0;*/
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;


    /** light **/
    --ion-color-light: #444444;
    --ion-color-light-rgb: 68,68,68;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #3c3c3c;
    --ion-color-light-tint: #575757;

  }
}


html.ios {
  --ion-default-font: -apple-system, BlinkMacSystemFont, "Arial", sans-serif;
}

html.md {
  --ion-default-font: "Arial", sans-serif;
}

html {
  --ion-font-family: var(--ion-default-font);
}


:root{
  --color: var(--ion-color-dark);
  --ion-text-color: var(--ion-color-dark);
  --ion-text-color-rgb: var(--ion-color-dark-rgb);
  --ion-item-color: var(--ion-color-dark);
  --background: var(--ion-color-white);
}
@media (prefers-color-scheme: dark) {
  :root{
    /*--color: var(--ion-color-light);
    --ion-text-color: var(--ion-color-light);
    --ion-text-color-rgb: var(--ion-color-light-rgb);
    --ion-item-color: var(--ion-color-light);*/
    /*--background: var(--ion-color-dark);*/
  }
}
:root {
  body.dark{
    /*--color: var(--ion-color-light);
    --ion-text-color: var(--ion-color-light);
    --ion-text-color-rgb: var(--ion-color-light-rgb);
    --ion-item-color: var(--ion-color-light);*/
    /*--background: var(--ion-color-dark);*/
  }
}

.sc-ion-modal-md-h{
  --max-height: 90%;
  --max-width: 90%;
  --border-radius: 4px;
  --background: transparent;
  @media only screen and (min-height: 768px) and (min-width: 768px){
    --width: 700px;
    --height: 700px;
  }
  @media only screen and (min-height: 868px) and (min-width: 1024px){
    --width: 800px;
    --height: 800px;
  }
}
.modal-wrapper.sc-ion-modal-md{
  // on cache l'ombre pcq sinon elle se voit au-dessus du header en tablet
  box-shadow: none;
  overflow: visible;
}
.inner-scroll{
  /*--background: var(--ion-color-white);*/
  background: var(--background);
}
.header-md{
  background: var(--ion-color-white);
}

.sc-ion-alert-md-h {
  --background: var(--ion-overlay-background-color,var(--ion-color-white,--ion-color-white));
}
.alert-sub-title.sc-ion-alert-md, 
.alert-title.sc-ion-alert-md {
  color: var(--ion-color-dark,--ion-color-dark);
}
.alert-checkbox-group.sc-ion-alert-md, 
.alert-radio-group.sc-ion-alert-md {
  border-top: 1px solid var(--ion-color-light,--ion-color-light);
  border-bottom: 1px solid var(--ion-color-light,--ion-color-light);
}
.alert-radio-label.sc-ion-alert-md{
  color: var(--ion-color-dark,--ion-color-dark);
}
[aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: var(--ion-color-primary,--ion-color-primary);
}