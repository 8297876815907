
.appname{
  color: var(--ion-color-grey);
  font-weight: bold;
}
.version{
  color: var(--ion-color-grey);
  font-size: 0.9rem;
}
.debug{
  color: var(--ion-color-grey);
  font-size: 0.7rem;
  font-style: italic;
}

#about{
  .item.sc-ion-label-md-h, .item .sc-ion-label-md-h{
    font-weight: bold;
    /*--color: var(--ion-color-grey-shade);*/
  }
}