.countdown-box{
  background: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
  transition: 0.3s;
  position: relative;
  &.hidden{
    margin-top: -32px;
  }
  &.displayed{
    margin-top: 0;
  }

  &:not(.hidden):before{
    left: 0;
    top: -5px;
    position: absolute;
    width: 100%;
    height: 5px;
    background-repeat: repeat-x;
    box-shadow: 0px 2px 2px var(--ion-color-light);
    content: "";
    z-index: 99999;
  }
  &:after{
    left: 0;
    bottom: -5px;
    background-position: left 0 top -2px;
    position: absolute;
    width: 100%;
    height: 5px;
    // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMV…h/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
    background-repeat: repeat-x;
    box-shadow: -2px -2px 2px var(--ion-color-light);
    content: "";
  }

  .title{
    font-weight: bold;
  }

  .alert-icon{
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background: #fff;
  }

  .countdown-status{
    font-size: 0.8rem;
  }
  
}
