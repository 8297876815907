$cardHeight: 79px;

.scene{
  perspective: 600px;
}
.card{
  transition: transform 1s;
  transform-style: preserve-3d;
  // transform-origin: center right;
  height: $cardHeight + 20px;
  position: relative;
  .card-content-md{
    height: $cardHeight;
  }
  &.is-flipped {
    transform: rotateY(-180deg);
  }
  .card-face{
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    &.card-face--front {
      .sc-ion-card-md-h{
        --background: var(--ion-color-white,transparent);
      }
    }
    &.card-face--back {
      transform: rotateY( 180deg );
      .sc-ion-card-md-h{
        --background: var(--ion-color-light,transparent);
      }
    }
  }
  &.greyed{
    opacity: 0.5;
    z-index: 0;
  }
}


ion-card{
  transition: 0.3s;
  .hidden &{
    animation: hide 0.5s forwards ease-in-out;;
  }
  .show &{
    animation: show 0.5s forwards ease-in-out;;
  }
}

@keyframes hide {
  0% {
    transform: none;
    opacity: 1;
    height: $cardHeight;
    margin: 10px;
  }

  50% {
    transform: translateX(-100%);
    opacity: 0;
    height: $cardHeight;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
    height: 0;
    margin: 0px;
  }
}

@keyframes show {
  0% {
    transform: translateX(-100%);
    opacity: 0;
    height: 0;
    margin: 0px;
  }
  50% {
    transform: translateX(-100%);
    opacity: 0;
    height: $cardHeight;
  }
  100% {
    transform: none;
    opacity: 1;
    height: $cardHeight;
    margin: 10px;
  }
}

ion-card{
  .bookmark{
    position: absolute;
    top: -8px;
    right: 0;
  }
}

